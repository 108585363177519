@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color:#000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  line-height: 1;
}
.bold {
  font-weight: bolder;
}
